import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	chakra,
} from "@chakra-ui/react";
import { cn } from "@peerigon/pupper/tailwind";
import { ServiceOverviewSectionItemRecord } from "src/__generated__/client.codegen";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import {
	isStructuredText,
	StructuredText,
} from "src/components/StructuredText/StructuredText";
import { PlusMinus } from "src/icons/PlusMinus";

type Item = Pick<
	ServiceOverviewSectionItemRecord,
	"service" | "description" | "id"
>;
export type ServiceOverviewSectionProps = {
	title: string;
	items: Array<Item>;
};
export const ServiceOverviewSection: React.FC<ServiceOverviewSectionProps> = ({
	title,
	items,
}) => {
	return (
		<chakra.div className="py-8 bg-brand-light-bright text-brand-blue">
			<Hx size="h2" className="text-center py-5 mb-3">
				{title}
			</Hx>
			<HeadingLevelBoundary>
				<Accordion
					allowMultiple
					defaultIndex={[]}
					className="max-w-screen-lg mx-auto px-4"
				>
					{items.map((props) => (
						<ServiceOverviewSectionItem key={props.id} {...props} />
					))}
				</Accordion>
			</HeadingLevelBoundary>
		</chakra.div>
	);
};

const ServiceOverviewSectionItem = ({ service, description }: Item) => {
	return (
		<AccordionItem>
			{({ isExpanded }) => (
				<>
					<Hx size="h5">
						<AccordionButton
							className={cn(
								"flex text-left hover:!bg-brand-light !py-5 border-b-[1.5px] border-brand-light",
								isExpanded && "bg-brand-light",
							)}
						>
							<span className="grow">{service}</span>
							<PlusMinus isActive={isExpanded} />
						</AccordionButton>
					</Hx>

					<AccordionPanel className="bg-white [&>ul>li:last-of-type]:mb-0">
						{isStructuredText(description) ? (
							<StructuredText data={description} />
						) : null}
					</AccordionPanel>
				</>
			)}
		</AccordionItem>
	);
};

// 🔬 e2e: story
