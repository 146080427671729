import dynamic from "next/dynamic";
import { GetPageQuery } from "src/__generated__/cms-schema.codegen";
import { Benefit } from "src/components/Benefit/Benefit";
import { readVariant as readLinkCardVariant } from "src/components/LinkCard/LinkCard";
import { ProductOverview } from "src/components/ProductOverview/ProductOverview";
import {
	isStructuredText,
	StructuredText,
} from "src/components/StructuredText/StructuredText";
import { createContext } from "src/lib/react";
import { AboutSection } from "./AboutSection/AboutSection";
import { BlogpostSection } from "./BlogpostsSection/BlogpostsSection";
import { CallToActionSection } from "./CallToActionSection/CallToActionSection";
import { ContactSection } from "./ContactSection/ContactSection";
import { DemoSection } from "./DemoSection/DemoSection";
import { FaqSection } from "./FaqSection/FaqSection";
import { FlipperSection } from "./FlipperSection/FlipperSection";
import { FunctionalitySection } from "./FunctionalitySection/FunctionalitySection";
import { ImageTextSection } from "./ImageTextSection/ImageTextSection";
import { LinkCardsSection } from "./LinkCardsSection/LinkCardsSection";
import { LogoWallSection } from "./LogoWallSection/LogoWallSection";
import { Mission } from "./Mission/Mission";
import { NavigationSection } from "./NavigationSection/NavigationSection";
import { NewsletterSection } from "./NewsletterSection/NewsletterSection";
import { NewsletterSignup } from "./NewsletterSignup/NewsletterSignup";
import { ProductComparison } from "./ProductComparison/ProductComparison";
import { ServiceBenefits } from "./ServiceBenefits/ServiceBenefits";
import { ServiceOverviewSection } from "./ServiceOverviewSection/ServiceOverviewSection";
import { TextSection } from "./TextSection/TextSection";
import { UserActionSection } from "./UserActionSection/UserActionSection";
import { VideoCollectionSection } from "./VideoCollectionSection/VideoCollectionSection";
import { VideoSection, readVariant } from "./VideoSection/VideoSection";
import { MissionSliderProps } from "../components/MissionSlider/MissionSlider";
import type { HeroProps } from "./Hero/Hero";
import type { OffersProps } from "./Offers/Offers";
import type { PersonaProps } from "./Persona/Persona";
import type { PremiumCalcHeroProps } from "./PremiumCalcHero/PremiumCalcHero";
import type { CustomerReviewSectionProps } from "src/components/CustomerReviewSection/CustomerReviewSection";
import type { HeroSliderProps } from "src/components/HeroSlider/HeroSlider";
import type { SwiperSlideProps } from "swiper/react";

export type Section = NonNullable<GetPageQuery["page"]>["content"][number];

export const [BubbleContainerContext, useBubbleContainerContext] =
	createContext<{
		isFirst: boolean;
		isLast: boolean;
	}>("BubbleContainer");

// big chunks in bundle analyzer

const Hero = dynamic<HeroProps>(async () =>
	import("./Hero/Hero").then((mod) => mod.Hero),
);

const PremiumCalcHero = dynamic<PremiumCalcHeroProps>(async () =>
	import("./PremiumCalcHero/PremiumCalcHero").then(
		(mod) => mod.PremiumCalcHero,
	),
);

const Offers = dynamic<OffersProps>(async () =>
	import("./Offers/Offers").then((mod) => mod.Offers),
);

const Persona = dynamic<PersonaProps>(async () =>
	import("./Persona/Persona").then((mod) => mod.Persona),
);

// uses swiper

const CustomerReviewSection = dynamic<CustomerReviewSectionProps>(async () =>
	import("../components/CustomerReviewSection/CustomerReviewSection").then(
		(mod) => mod.CustomerReviewSection,
	),
);

const HeroSlider = dynamic<HeroSliderProps>(async () =>
	import("../components/HeroSlider/HeroSlider").then((mod) => mod.HeroSlider),
);

const HeroSliderItem = dynamic<SwiperSlideProps>(async () =>
	import("../components/HeroSlider/HeroSlider").then((mod) => {
		// Without this, Swiper will not work.
		return mod.HeroSliderItem;
	}),
);

HeroSliderItem.displayName = "SwiperSlide";

const MissionSlider = dynamic<MissionSliderProps>(async () =>
	import("../components/MissionSlider/MissionSlider").then(
		(mod) => mod.MissionSlider,
	),
);

const MissionSliderItem = dynamic<SwiperSlideProps>(async () =>
	import("../components/MissionSlider/MissionSlider").then((mod) => {
		return mod.MissionSliderItem;
	}),
);

MissionSliderItem.displayName = "SwiperSlide";

const renderSection = (section: Section, anchorId: string) => {
	if (section.__typename === "HeroRecord") {
		return section.background.responsiveImage == null ? null : (
			<Hero
				title={section.title}
				id={`section ${section.id}`}
				subline={section.subline ?? undefined}
				background={section.background}
				cta={section.cta}
				variant={section.style}
				text={isStructuredText(section.text) ? section.text : undefined}
				priceTagText={section.priceTagText ?? undefined}
				startingPrice={section.startingPrice ?? undefined}
			/>
		);
	} else if (section.__typename === "HeroSliderRecord") {
		return (
			<HeroSlider>
				{section.slides.map((slide) => (
					<HeroSliderItem key={slide.id}>
						<Hero
							id={`section ${section.id}/${slide.id}`}
							title={slide.title}
							subline={slide.subline ?? undefined}
							background={slide.background}
							cta={slide.cta}
							variant={slide.style}
							text={
								isStructuredText(slide.text)
									? slide.text
									: undefined
							}
							priceTagText={slide.priceTagText ?? undefined}
							startingPrice={slide.startingPrice ?? undefined}
						/>
					</HeroSliderItem>
				))}
			</HeroSlider>
		);
	} else if (section.__typename === "PremiumCalcHeroRecord") {
		return (
			<PremiumCalcHero
				subline={section.subline ?? undefined}
				title={section.title}
				video={{
					poster: section.video.video.thumbnailUrl,
					src: section.video.video.streamingUrl,
				}}
			/>
		);
	} else if (section.__typename === "ServiceBenefitRecord") {
		return (
			<ServiceBenefits
				title={section.title}
				totalCount={section.benefits.length}
				variant={section.iconPosition === "left" ? "alt" : undefined}
			>
				{section.benefits.map((benefit) => (
					<Benefit
						key={benefit.id}
						title={benefit.title}
						text={benefit.text}
						icon={benefit.icon}
						variant={section.iconPosition}
					/>
				))}
			</ServiceBenefits>
		);
	} else if (
		section.__typename === "PersonaRecord" &&
		isStructuredText(section.content)
	) {
		return (
			<Persona
				backgroundVideo={{
					poster: section.video.video.thumbnailUrl,
					src: section.video.video.streamingUrl,
				}}
				structuredText={section.content}
				recommendationIcon={section.recommendationIcon}
			/>
		);
	} else if (section.__typename === "MissionRecord") {
		return (
			<Mission
				variant={
					section.style === "white" ? "whiteBackground" : undefined
				}
				imageData={section.image ? section.image : undefined}
			>
				{isStructuredText(section.text) && (
					<StructuredText data={section.text} />
				)}
			</Mission>
		);
	} else if (section.__typename === "MissionSliderRecord") {
		return (
			<MissionSlider>
				{section.slides.map((slide) => (
					<MissionSliderItem key={slide.id}>
						<Mission
							variant={
								slide.style === "white"
									? "whiteBackground"
									: undefined
							}
							imageData={slide.image ? slide.image : undefined}
						>
							{isStructuredText(slide.text) && (
								<StructuredText data={slide.text} />
							)}
						</Mission>
					</MissionSliderItem>
				))}
			</MissionSlider>
		);
	} else if (
		section.__typename === "OffersBlockRecord" &&
		isStructuredText(section.offerText)
	) {
		return <Offers structuredText={section.offerText} />;
	} else if (
		section.__typename === "ProductComparisonRecord" &&
		isStructuredText(section.products)
	) {
		const hasTopicSelector = Boolean(section.topicSelector);

		if (hasTopicSelector) {
			return (
				<ProductOverview
					structuredText={section.products}
					hasTopicSelector={hasTopicSelector}
					topicSelectorText={section.topicSelectorText}
					introAlignment={section.introAlignment}
				/>
			);
		}

		return (
			<ProductComparison
				id={section.id}
				introAlignment={section.introAlignment}
				structuredText={section.products}
			/>
		);
	} else if (section.__typename === "NewsletterSignupRecord") {
		return (
			<NewsletterSignup
				action={section.action}
				title={section.title}
				intro={section.intro}
			/>
		);
	} else if (section.__typename === "NewsletterSectionRecord") {
		return (
			<NewsletterSection
				topContent={section.topContent}
				bottomContent={section.bottomContent}
			/>
		);
	} else if (
		section.__typename === "TextSectionRecord" &&
		isStructuredText(section.sectionText)
	) {
		return (
			<TextSection
				structuredText={section.sectionText}
				showArrow={section.showArrow ?? false}
				variant={section.style}
				anchorId={anchorId}
				backgroundColor={section.backgroundColor.hex}
				color={section.fontColor.hex}
			/>
		);
	} else if (section.__typename === "FaqSectionRecord") {
		return (
			<FaqSection
				title={section.sectionTitle}
				subtitle={section.sectionSubtitle}
				text={section.faqSectionText}
				faqs={section.faqs}
				hasSearch={Boolean(section.hasSearch)}
			/>
		);
	} else if (section.__typename === "CustomerReviewsSectionRecord") {
		return (
			<CustomerReviewSection
				id={section.id}
				title={section.title}
				reviews={section.reviews}
			/>
		);
	} else if (section.__typename === "CtaSectionRecord") {
		return (
			<CallToActionSection
				title={section.title}
				description={section.description}
				linkButtons={section.linkButtons}
			/>
		);
	} else if (section.__typename === "ContactSectionRecord") {
		return (
			<ContactSection
				title={section.title}
				subtitle={section.subtitle}
				contact={section.contact}
			/>
		);
	} else if (section.__typename === "DemoSectionRecord") {
		return (
			<DemoSection
				title={section.title}
				subtitle={section.subtitle}
				demoText={section.demoText}
				contact={section.contact}
			/>
		);
	} else if (
		section.__typename === "AboutRecord" &&
		isStructuredText(section.aboutSectionText)
	) {
		return <AboutSection structuredText={section.aboutSectionText} />;
	} else if (section.__typename === "LogoWallSectionRecord") {
		return <LogoWallSection title={section.title} logos={section.logos} />;
	} else if (section.__typename === "VideoCollectionRecord") {
		return (
			<VideoCollectionSection
				intro={
					isStructuredText(section.text) ? section.text : undefined
				}
				columns={section.columns}
			/>
		);
	} else if (section.__typename === "FunctionalityCollectionRecord") {
		return (
			<FunctionalitySection
				intro={
					isStructuredText(section.text) ? section.text : undefined
				}
				columns={section.columns}
			/>
		);
	} else if (section.__typename === "FlipperSectionRecord") {
		return (
			<FlipperSection
				intro={
					isStructuredText(section.text) ? section.text : undefined
				}
				limit={section.pageLimitMobile}
				flippers={section.flippers}
			/>
		);
	} else if (section.__typename === "ImageTextSectionRecord") {
		return (
			<ImageTextSection
				imageData={section.imageTextSectionImage}
				structuredText={
					isStructuredText(section.imageTextSectionText)
						? section.imageTextSectionText
						: undefined
				}
			/>
		);
	} else if (section.__typename === "BlogpostSectionRecord") {
		return (
			<BlogpostSection
				intro={
					isStructuredText(section.introText)
						? section.introText
						: null
				}
				topicSelector={Boolean(section.topicSelector)}
				topicSelectorText={section.topicSelectorText}
			/>
		);
	} else if (section.__typename === "UserActionSectionRecord") {
		return (
			<UserActionSection
				headline={section.headline ?? undefined}
				userActions={section.userActions}
			/>
		);
	} else if (section.__typename === "NavigationSectionRecord") {
		return <NavigationSection links={section.links} />;
	} else if (section.__typename === "LinkCardsSectionRecord") {
		return (
			<LinkCardsSection
				cards={section.cards.map((card) => ({
					...card,
					variant: readLinkCardVariant(card.variant),
				}))}
			/>
		);
	} else if (section.__typename === "VideoSectionRecord") {
		return (
			<VideoSection
				title={section.introTitle ?? undefined}
				topline={section.topline ?? undefined}
				topic={section.topic ?? undefined}
				topicSubline={
					isStructuredText(section.topicSubline)
						? section.topicSubline
						: undefined
				}
				text={
					isStructuredText(section.topicText)
						? section.topicText
						: undefined
				}
				fullBleed={Boolean(section.fullBleed)}
				asset={
					section.asset.video
						? {
								type: "video",
								src: section.asset.video.streamingUrl,
								poster: section.asset.video.thumbnailUrl,
							}
						: { type: "image", ...section.asset }
				}
				button={section.button ?? undefined}
				persona={
					section.persona
						? {
								...section.persona,
								text: isStructuredText(section.persona.text)
									? section.persona.text
									: undefined,
							}
						: undefined
				}
				variant={readVariant(section.variant)}
			/>
		);
	} else if (section.__typename === "ServiceOverviewSectionRecord") {
		return (
			<ServiceOverviewSection
				title={section.title}
				items={section.items}
			/>
		);
	}

	return null;
};

export { renderSection };

// 🔬 TBD: Please evaluate
